const url = {
  search: {
    search: "/search?search=",
  },
  instructors: "/instructor/getAllInstructors",
  courseDetail: "/userInfo/getCourseById",
  logDetail: "/log/getAllLog",
  instructor: {
    getUserDetails: "/userInfo/getUserInfoById/",
    createCourse: "/courses/course",
    editCourse: "/courses/course",
    getPaymentDetail: "/admin/instructorPayments/",
    getInstructorProfile: "/instructor-profile/getProfile",
    all: "/instructor/all",
    updateUserInfo: "/admin/updateUserInfo",
  },
  enrollment: "/userInfo/getEnrollmentById/",
  enrollments: {
    edit: "/enrollments/edit",
  },
  deleteEnrollmentInvoice: "/enrollment-invoices/invoice/delete",
  student: {
    getUserById: "/userInfo/getUserInfoById/",
    getStudentStats: "/students/getStudentStats/",
    getPaymentDetail: "/admin/studentPayments/",
    all: "/students/all",
    all_paid: "/students/all_paid",
  },
  login: "/auth/login",
  messages: "/message/getMessage/",
  users: "/message/searchUser/",
  booking: {
    getAllPaidEnrollments: "/admin/getAllPaidEnrollments",
    bulkbookSessions: "/admin/session",
    getAllEnrollments: "/enrollments/getAllEnrollmentByType",
    getAllPaidMusicEnrollments: "/admin/getAllPaidMusicTherapyEnrollments",
  },
  renewal: {
    getById: "/renewals/getById",
    renewalStudents: "/renewals/getStudents",
    getSummary: "/renewals/getSummary",
    addNote: "/renewals/addNote",
  },
  createDemoSession: "/enrollments/createDemoEnrollment",
  getAllStudents: "/admin/getAllStudents",
  getAllStudentsCSV: "/csv/allStudents",
  getGlobalEnrollment: "/csv/globalEnrollmentChecking",
  paid_students_csv: "/csv/get/students/paid",
  getInternalEnrollment: "/csv/internalEnrollmentChecking",
  getActiveTeachersCsv: "/csv/getActiveTeachers",
  getAllInstructor: "/admin/getAllInstructors",
  createPaymentLink: "/admin/createPaymentLink",
  csvOfBookedSession: "/admin/csvOfBookedSchedules",
  getInstructorCoursesbyId: "/instructor/getCoursesByInstructorId/",
  getInstructorCourses: "/admin/getInstructorCourses/",
  getAllPaymentDetails: "/admin/getAllPaymentDetails",
  createPayment: "/admin/createPaymentLink",
  getAllCoordinators: "/admin/list",
  getUnapprovedInstructors: "/instructor/getUnapproveInstructors",
  getInstructorStatus: "/instructor/getInstructorStatusByInstructorId/",
  updateInstructorStatus: "/instructor/updateInstructorStatusByInstructorId/",
  getActiveStudentCsv: "/csv/getActiveStudents",
  getExtraClassesCSV: "/csv/getExtraClassesCSV",
  getDhunguruDiscountCSV: "/csv/getDhunguruDiscountCSV",
  getAllCSVTypes: "/csv/getAllCsvEnums",
  getInstructorCsv: "/csv/getInstructorsForCsv",
  getInstructorConversionCSV: "/csv/getInstructorConversionCSV",
  getStudentCsv: "/csv/getStudentsForCsv",
  getDemoSessionsCsv: "/csv/getDemoSessionsForCsv",
  getInvoicesCsv: "",
  downloadRecordingForDateRangeCsv: "",
  getBulkData: "",
  getEnrollmentCsv: "/csv/getEnrollmentsForCsv",
  getInstructorEnrollment: "/instructor/getEnrollmentsByInstructorId/",
  getInstructorSession: "/instructor/getAllSessionTypesByInstructorId/",
  getAllSessions: "/admin/sessions/search",
  createSession: "/admin/session",
  getStudentSessionData: "/students/getSchedulesByStatus/",
  getStudentEnrollment: "/students/getEnrollmentByStudentId/",
  getAllCrons: "/general/getCronJobsByUserId",
  getKylasId: "/general/getKylasIdByUserId",
  getPaymentzEnrollments: "/csv/razorpayCsv",
  getAdminLogsCsv: "/csv/get/admin/logs",
  getAllEnrollmentsCsv: 'csv/get/enrollments/all',
  sessions: {
    deleteSession: "/admin/deleteScheduleById",
    getByDateRange: "/csv/downloadSessionsForDateRange",
  },
  invoice: {
    getCancelledIndiaInvoices: "/enrollment-invoices/get/cancelledInvoices",
    getCancelledInternationalInvoices:
      "/enrollment-international-invoices/get/cancelledInvoices",
    getCancelledMusicIndiaInvoices:
      "/music-therapy/enrollment-invoices/get/cancelledInvoices",
    getCancelledMusicInternationalInvoices:
      "/music-therapy/enrollment-international-invoices/get/cancelledInvoices",
    getLastStudentPayment: "/invoice/getLastStudentPayment",
    getAllInvoices: "/enrollment-invoices/get",
    getAllInvoicesInternational: "/enrollment-international-invoices/get",
    createManualInvoice: "/enrollment-invoices/create-invoice",
    createManualInternationalInvoice:
      "/enrollment-international-invoices/create",
    getInvoices: "/invoice/get",
    fetchAllInvoices: "/invoice/invoice-summary",
    createPartialInvoice: "/enrollment-invoices/create-partial-invoice",
    getInvoiceData: "/invoice/data",
    recordInvoices: "/invoice/record-invoice",
    getAllMusicInvoices: "/music-therapy/enrollment-invoices/get",
    getAllMusicInternationalInvoices:
      "/music-therapy/enrollment-international-invoices/get",
    createMusicManualInternationalInvoice:
      "/music-therapy/enrollment-international-invoices/create",
    createMusicManualInvoice:
      "/music-therapy/enrollment-invoices/create-invoice",
  },
  updateStudentDetail: "/students/updateStudentDetails/",
  createManualEnrollment: "/enrollments/createManualEnrollment",
  getAllSessionsForStudentOrInstructor:
    "/admin/getAllSessionsForStudentOrInstructor",
  instRecordingUserSearch: "/admin/searchUser",
  getEnrollmentDetail: "/enrollments/getEnrollmentById/",
  getDemoSessions: "/admin/getAllDemoSessions",
  getSessionByEnrollment: "/admin/getSessionByEnrollment/",
  getUserRoles: "/admin/getAllUserRoles",
  getRecordingByBookingId: "/admin/getRecordingByBookingId",
  getUserEnums: "",
  userRole: {
    getAllAdminUsers: "/admin/getUserByAdminRoles",
    changeUserRole: "/admin/assignUserRoles",
    blockUnblockUser: "/admin/toggleisBlocked",
    createAdmin: "/admin/createAdmin",
    register: "/users/register",
    getUserAdminRoles: "/admin/getUserAdminRoles",
    getAdminById: "/admin/getAdminById/",
  },
  getAllBookingIds: "/admin/getAllBookingIds",
  changeAdminPassword: "/admin/changeAdminPassword",
  updateUserInfo: "/admin/updateUserInfo",
  deleteRazorPayLink: "/admin/deleteRazorpayLink",
  getInstructor: "/instructor/getInstructor",
  paymentSync: "/admin/verifyLinkStatus",
  paytmSync: "/admin/verifyPaytmLinkStatus",
  cashfreeSync: "/admin/verifyCashfreeLinkStatus",
  updateCouncellor: "/enrollments/update-councellor",
  postFlaggedResponse: "/admin/flag-meetings/response",
  getRenewalCsv: "/csv/renewalCsv",
  createInstructorFine: "/invoice/createFine",

  announcement: {
    get: "/announcements/get",
    create: "/announcements/create",
    update: "/announcements/update",
    delete: "/announcements/delete",
  },
  sessionBulkBooking: "/admin/session/bulkBooking",
  syncRecordingFromZoom: "/admin/saveZoomRecording",
  updateCommssion: "/admin/commission/update",
  getSessionDetailsById: "/sessions/get",

  getPaidStudents: "/admin/students/paid",
  getRenewals: "/renewals/get",
  createRenewal: "/renewals/create",
  updateRenewal: "/renewals/update",
  deleteRenewal: "/renewals/delete",
  getRenewalEnums: "/admin/renewals/enum",

  incentive: {
    getAllIncentives: "/incentives/get",
    createIncentive: "/incentives/create",
    deleteIncentive: "/incentives/delete",
    getIncentivesCSVByDateRange: "/csv/getIncentiveCSV",
  },

  blockedEnrollments: {
    getAllBlockedEnrollments: "/admin/getAllBlockedEnrollments",
    unblockEnrollment: "/admin/unblockEnrollment",
    blockEnrollment: "/admin/blockEnrollment",
  },

  callLogs: {
    getAllCallLogs: "/callLogs/get",
    syncCallRecordings: "/callLogs/sync",
  },
  version: {
    getAll: "/admin/version/all",
    deleteVersion: "/admin/version/delete",
    createVersion: "/admin/version/create",
    updateVersion: "/admin/version/update",
  },
  sales: {
    createSaleItem: "/sales/items/create",
    getAllSalesItems: "/sales/items/get",
    changeAvailability: "/sales/items/patch",
    updateSalesItem: "/sales/items/put",
    createLink: "/sales/links/create",
    getLink: "/sales/links/get",
    getAllInvoices: '/sales/salesInvoice/getInvoice',
    getCancelledInvoice: "/sales/salesInvoice/getCancelledInvoice",
    createInvoice: '/sales/invoice/create',
    cancleInvoice: "/sales/salesInvoice/cancelInvoice",
    createPdf: "/sales/salesInvoice/pdf",
    markComplete: "/sales/complete",
    createSaleCoupon: '/sales/coupon/create',
    getAllSaleCoupon: "/sales/coupon/get",
    updateSaleCoupon: "/sales/coupon/update",
    checkCoupon: "/sales/coupon/check",
  },
  getPaymentLink: "/admin/payment-link",
  markPaymentLinkCompleted: "/admin/rzp-payment/mark-completed",
  toggleBlock: "/admin/user/toggle/block",
  getCityByPincode: "admin/getCityByPincode",
  getFreshworksDeals: "/userInfo/freshworks/deals",
  updateFreshworksStage: "/api-route/updateDealStage",
  addFreshworksDeals: "/userInfo/freshworks/deals",
  editFreshworksDealId: "/admin/update/freshworks/dealId",
  getFreshWorksLeadIdByDhunguruId: "/admin/get/freshSalesId",
  manualUpdateFreshworksId:"/admin/update/freshSales",
  updateExtraClassesByAdmin: '/admin/update/extra/classes',
  getExtraClassesByAdmin: '/admin/get/extra/classes',
  getExtraClassesByLoggedInAdmin: '/admin/get/extra/classes/signed/admin',
  createManualDeal: '/admin/create/deal/manual',
  syncEnrollmentManual: '/admin/sync/enrollment/manual',
  getDealByDealId:"/admin/get/deal",
  updateDealStage:"/admin/update/deal/stage",
  updateDealAmount:"/admin/update/deal/amount",
  deleteDealByDealId:"/admin/delete/deal",
};

export default url;
